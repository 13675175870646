import { mainAxios } from '@/plugins/axios';

class equipmentService {
  async getEquipment(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }

  async getEquipmentById(id) {
    const response = await mainAxios.get(`/equipamento/${id}`);
    return response.data;
  }

  async createSignAc(cnpj) {
    const response = await mainAxios.post('/assinatura-sat', {
      cnpjDesenv: '52387356000123',
      cnpjCliente: cnpj
    });
    return response.data;
  }

  async getCompany() {
    let url = `/empresas?columns=cod_emp,rsocial_emp,fantasia_emp&paginate=false`;
    const response = await mainAxios.get(url);
    return response.data;
  }

  async getApp() {
    let columns =
      'cod_emp,chave_host_md5,cod_hosts,hostname_hosts,tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts';
    const response = await mainAxios.get(
      `/equipamento?columns=${columns}&paginate=false&scope=app`
    );
    return response.data;
  }

  async getCompanyById(id, columns = '') {
    let url = `/empresas/${id}?columns=${columns}`;

    const response = await mainAxios.get(url);
    return response.data;
  }

  async createEquipment(payload = {}) {
    return await mainAxios.post('/equipamento', payload);
  }

  async updateEquipment(id, payload) {
    return await mainAxios.put(`/equipamento/${id}`, payload);
  }

  async deleteRedirectSat(id) {
    return await mainAxios.delete(`/equipamento/redirecionamento-sat/${id}`);
  }

  async deleteEquipment(id, host) {
    return await mainAxios.delete(`/equipamento/${id}/${host}`);
  }


  async linkQrCode(id) {
    return await mainAxios.get(`/equipamento/vincular/${id}`);
  }
}

export default new equipmentService();
